// TODO: Fix a11y stuff
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useIntl } from "react-intl";

import { useDesktopNavigationContext } from "./DesktopNavigationContext";

import LocalizedLink from "modules/layout/components/LocalizedLink";

const MegaDropdownItem = ({
  pageId,
  node,
  type,
}: {
  pageId: string;
  node: any;
  type: "default" | "show_all";
}) => {
  const intl = useIntl();

  const { setHoverPath, navigatedPath, hoverPath } =
    useDesktopNavigationContext();

  const handleMouseEnter = () => {
    setHoverPath(pageId);
  };

  const handleTouchStart = () => {
    setHoverPath(pageId);
  };

  return (
    <LocalizedLink
      page={{ pageId }}
      className={`mega-link ${
        type === "default" && node.children.length ? "has-arrow" : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onTouchStart={handleTouchStart}
      data-in-hover-path={type === "default" && hoverPath?.includes(pageId)}
      data-in-navigation-path={
        type === "default" && navigatedPath.includes(pageId)
      }
    >
      {type === "default" ? (
        node.title
      ) : (
        <b>
          {node.title}
          &nbsp;
        </b>
      )}

      {type === "show_all" ? (
        <small className="ml-2">
          {"/shop".includes(node.url.route)
            ? intl.formatMessage({
                id: "show_all",
                defaultMessage: "Alle anzeigen",
              })
            : intl.formatMessage({
                id: "overview",
                defaultMessage: "Übersicht",
              })}
        </small>
      ) : (
        ""
      )}
    </LocalizedLink>
  );
};

const MegaDropdownColumn = ({ node, pageId }) => {
  return (
    <div className="mega-col">
      <MegaDropdownItem pageId={pageId} node={node} type="show_all" />

      {node.children.length &&
        node.children
          ?.filter(({ url }) => url)
          .map((subnode) => (
            <MegaDropdownItem
              key={subnode.url.route}
              pageId={subnode.url.route}
              node={subnode}
              type="default"
            />
          ))}
    </div>
  );
};

export default MegaDropdownColumn;
