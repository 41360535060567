import { useCallback } from "react";
import { useIntl } from "react-intl";
import boldMatch from "../../common/utils/boldMatch";

const strIncludesBold = (str) => /<b>|<\/b>/g.test(str || "");

const SuggestionListItem = ({
  suggestion,
  onSuggestionClick,
  searchTerm,
  ...rest
}) => {
  const hasBTag = useCallback(strIncludesBold, [strIncludesBold]);
  const { formatMessage } = useIntl();

  const { rootAssortment } = suggestion;

  return (
    <button
      aria-label="Search suggestion"
      key={suggestion._id}
      className="searched-item no-button w-100 py-1 px-2 mb-2"
      type="button"
      onClick={onSuggestionClick}
      {...rest}
    >
      <span
        className="mr-1"
        dangerouslySetInnerHTML={{
          __html: hasBTag(suggestion.text)
            ? suggestion.text
            : boldMatch({ value: suggestion.text, highlight: searchTerm }),
        }}
      />
      {rootAssortment && (
        <span className="d-inline-block color-bg-blue-light font-size-small py-1 px-2 br-9 line-height-1-22">
          {formatMessage(
            {
              id: "category-suggestion-in-root",
              defaultMessage: "in {rootCategoryTitle}",
            },
            {
              rootCategoryTitle: rootAssortment.title,
            },
          )}{" "}
        </span>
      )}
    </button>
  );
};

export default SuggestionListItem;
