import React, { useState, useContext, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";
import Icon from "../../common/components/Icon";
import LanguageSwitch from "./LanguageSwitch";
import MobileNavigation from "../../navigation/components/MobileNavigation";
import DesktopNavigation from "../../navigation/components/DesktopNavigation";
import RoutesContext from "../../navigation/RoutesContext";
import useSearchSuggestions from "../../search/hooks/useSearchSuggestions";
import SearchResultDropdown from "../../search/component/SearchResultDropdown";
import useSuggester from "../../common/utils/useSuggester";
import useDebouncedSearchTerm from "../../common/utils/useDebouncedSearchTerm";
import useCurrentClient from "modules/auth/hooks/useCurrentClient";
import useMenu, { COCKPIT_HEADER_MENU_NAME } from "../hooks/useMenu";
import LocalizedLink, { useLocalizedRoute } from "./LocalizedLink";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";

const EnvironmentIndicator = dynamic(() => import("./EnvironmentIndicator"), {
  ssr: false,
});

const UserSwitch = dynamic(() => import("../../auth/components/UserSwitch"), {
  ssr: false,
});
const CartSwitch = dynamic(() => import("../../cart/components/CartSwitch"), {
  ssr: false,
});

export default function Header({ searchQuery = "" }) {
  const intl = useIntl();
  const { currentClient } = useCurrentClient();
  const searchPageId = findPageIdByFilePath("/search/");
  const searchPageRoute = useLocalizedRoute({ pageId: searchPageId });
  const { storeSearchString } = useSuggester();
  const { menu } = useMenu({ name: COCKPIT_HEADER_MENU_NAME });

  const router = useRouter();
  const [searchTerm, setSearchTerm, debouncedSearchTerm, isDebounced] =
    useDebouncedSearchTerm(searchQuery, 500);

  const [isNavOpen, setNavOpenState] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const [isSticky, setSticky] = useState(false);

  const { setBodyOverflowHidden } = useContext(RoutesContext);

  const [ref, inView, entry] = useInView({
    threshold: 0,
  });
  const logoRef = useRef(null);

  const showMigelConformity = !!currentClient?.showMigelConformityInCatalog;

  const setNavOpen = (isOpen) => {
    setNavOpenState(isOpen);
    setBodyOverflowHidden(isOpen);
  };

  if (router?.events) {
    router.events.on("routeChangeStart", () => setNavOpen(false));
  }

  useEffect(() => {
    if (!!entry && !inView && !isSticky) {
      logoRef.current.width = "180";
      logoRef.current.height = "28";
      entry.target.style.height = `${entry.target.clientHeight - 5}px`;
      setSticky(true);
    }

    if (!!entry && inView && isSticky) {
      entry.target.style.height = "initial";
      logoRef.current.width = "226";
      logoRef.current.height = "35";
      setSticky(false);
    }
  }, [isSticky, inView, entry]);

  const onSearch = (searchVal) => {
    if (searchVal) {
      storeSearchString(searchVal);
      if (typeof searchVal === "string") {
        router.push(
          `${searchPageRoute.href.pathname}${encodeURIComponent(searchVal)}`,
        );
      } else if (typeof searchVal === "object" && searchVal?.text)
        router.push(
          `${searchPageRoute.href.pathname}${encodeURIComponent(
            searchVal.text,
          )}`,
        );
    }
  };

  const {
    suggestions,
    products,
    contents,
    loading,
    totalMatchedProducts,
    assortments,
  } = useSearchSuggestions({
    queryString: debouncedSearchTerm,
    contentLimit: 4,
    suggestLimit: 10,
    assortmentLimit: 4,
  });

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      onSearch(searchTerm);
    }
  };

  const viewAllProductMatch = () => {
    onSearch(searchTerm);
  };

  const onFocusDesktopSearch = () => {
    if (!showSearch) setShowSearch(true);
  };

  const onReset = () => {
    setSearchTerm("");
  };

  const dontTriggerDropdownClose = (e) => {
    if (!showSearch) setShowSearch(true);
    e.stopPropagation();
  };
  return (
    <div className="d-print-none" ref={ref}>
      <header
        className={`header color-bg-white ${
          isSticky ? "fixed-top sticky" : ""
        }`}
      >
        <SearchResultDropdown
          onClose={() => setShowSearch(false)}
          contentSuggestions={contents || []}
          productSuggestions={products}
          typeAheadSuggestions={suggestions}
          onTypeAheadClick={onSearch}
          dataIsLoading={loading || isDebounced}
          showSearch={showSearch}
          searchedTerm={searchTerm}
          foundProducts={totalMatchedProducts}
          categorySuggestions={assortments}
          onViewAllProductMatch={viewAllProductMatch}
          showMigelConformity={showMigelConformity}
        />

        <div className="container header-container color-bg-white-gradient">
          <nav className="nav nav--top hide-on-mobile">
            {(menu?.links || [])
              .filter(({ url, active }) => url && active)
              ?.map(({ url, title }) => (
                <LocalizedLink
                  className="nav--top__item"
                  page={{ pageId: url?.route }}
                  key={url?.route}
                >
                  {title}
                </LocalizedLink>
              ))}

            <LanguageSwitch />
          </nav>

          {/* mobile header */}
          <div className="mobile-header hide-on-not-mobile d-flex justify-content-start align-items-center">
            <button
              type="button"
              aria-label={intl.formatMessage({
                id: "menu",
                defaultMessage: "Menü",
              })}
              className="no-button mr-3 d-flex align-items-center py-1"
              onClick={() => setNavOpen(true)}
            >
              <Icon
                icon="navigation-menu"
                fill="currentColor"
                className="icon--medium mr-2"
              />
              {intl.formatMessage({ id: "menu", defaultMessage: "Menü" })}
            </button>

            <MobileNavigation
              isNavOpen={isNavOpen}
              doClose={() => setNavOpen(false)}
            />

            <LocalizedLink
              page={{ pageId: "/" }}
              title="Publicare"
              className="position-relative"
            >
              <>
                <EnvironmentIndicator />
                <div className="header-mobile-logomark mr-3">
                  <img
                    className="align-middle"
                    src="/static/img/publicare-logomark.png"
                    alt="Publicare Logo"
                  />
                </div>
                <div className="header-mobile-logo">
                  <img
                    ref={logoRef}
                    className="align-middle"
                    width="226"
                    height="35"
                    src="/static/img/publicare-logo.png"
                    alt="Publicare Logo"
                  />
                </div>
              </>
            </LocalizedLink>
          </div>

          {/* desktop header */}
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ minHeight: "43.4px" }}
          >
            <LocalizedLink
              page={{ pageId: "/" }}
              title="Publicare"
              tabIndex="-1"
            >
              <>
                <div className="header-logo hide-on-mobile">
                  <EnvironmentIndicator />
                  <img
                    ref={logoRef}
                    className="align-middle"
                    width="226"
                    height="35"
                    src="/static/img/publicare-logo.png"
                    alt="Publicare Logo"
                  />
                </div>
              </>
            </LocalizedLink>

            <div className="w-100-for-mobile d-block pt-1 pt-lg-0">
              <UserSwitch />
              <CartSwitch />
            </div>
          </div>

          <div className="header__bottom">
            <DesktopNavigation />

            <div className="search-bar">
              <span className="search-wrap position-relative">
                {/* desktop search */}
                <div className="position-relative">
                  <input
                    onKeyDown={handleEnter}
                    type="text"
                    className="button--dimmed form-field search-field"
                    value={searchTerm}
                    onMouseDown={dontTriggerDropdownClose}
                    onFocus={onFocusDesktopSearch}
                    placeholder={intl.formatMessage({
                      id: "search",
                      defaultMessage: "Suchen",
                    })}
                    onChange={(e) => {
                      if (!showSearch) setShowSearch(true);
                      setSearchTerm(e.target.value);
                    }}
                  />
                  {loading ? (
                    <Icon className="search-loading" icon="loading" />
                  ) : (
                    <input
                      className="search-clear"
                      aria-label="clear search filters"
                      type="reset"
                      onClick={onReset}
                      value="&#10005;"
                    />
                  )}
                  <label className="d-none">
                    {intl.formatMessage({
                      id: "search",
                      defaultMessage: "Suchen",
                    })}
                  </label>
                  <button
                    onClick={() => onSearch(searchTerm)}
                    className="no-button btn-search"
                    aria-label="Search"
                    type="button"
                  >
                    <Icon icon="search-1" fill="currentColor" />
                  </button>
                </div>
              </span>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
