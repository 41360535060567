import classNames from "classnames";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import useSuggester from "../../common/utils/useSuggester";

import SuggestedContentList from "./SuggestedContentList";
import SuggestedProductList from "./SuggestedProductList";
import TypeAheadSuggestionsList from "./TypeAheadSuggestionsList";

const SearchResultDropdown = ({
  productSuggestions,
  contentSuggestions,
  typeAheadSuggestions,
  dataIsLoading,
  onTypeAheadClick,
  onClose,
  showSearch,
  foundProducts,
  onViewAllProductMatch,
  searchedTerm,
  categorySuggestions,
  showMigelConformity,
}) => {
  const [init, setIsInit] = useState(true);
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);
  const [refresh, setRefresh] = useState(true);
  const {
    getPreviousSearchStrings,
    getPreviousVisitedProducts,
    getPreviousVisitedContent,
    getPreviousVisitedCategories,
    clearSearchHistory,
  } = useSuggester();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsLoading(true);
    };
    const handleRouteChangeComplete = () => {
      setIsLoading(false);
      onClose();
    };

    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEsc = (event) => {
      if (event.key === "Escape") onClose();
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keyup", handleEsc);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeStart", handleRouteChangeStart);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keyup", handleEsc);
    };
  }, []);

  const memoizedVisitedProducts = useMemo(
    () => getPreviousVisitedProducts(),
    [getPreviousVisitedProducts, refresh],
  );
  const memoizedVisitedContent = useMemo(
    () => getPreviousVisitedContent(),
    [getPreviousVisitedContent, refresh],
  );
  const memoizedPreviousSearch = useMemo(
    () => getPreviousSearchStrings(),
    [getPreviousSearchStrings, refresh],
  );
  const memoizedVisitedCategories = useMemo(
    () => getPreviousVisitedCategories(),
    [getPreviousVisitedCategories, refresh],
  );
  const hasHistory =
    memoizedPreviousSearch?.length ||
    memoizedVisitedContent?.length ||
    memoizedVisitedCategories.length ||
    memoizedVisitedProducts?.length;
  useEffect(() => {
    if (init) setIsInit(false);
  }, []);

  if (!hasHistory && !searchedTerm) return null;

  const hasResult =
    productSuggestions?.length ||
    contentSuggestions?.length ||
    categorySuggestions?.length ||
    typeAheadSuggestions?.length;
  if (init) return null;

  const inLoadingState = isLoading || dataIsLoading;

  return (
    <>
      <div
        className={classNames(
          "search-suggestion-dropdown-overlay fade-in-out",
          {
            visible: showSearch,
            hidden: !showSearch,
          },
        )}
      />
      <div
        className={classNames(
          "position-absolute color-bg-white search-focused pt-4",
          {
            visible: showSearch,
            hidden: !showSearch,
          },
        )}
        ref={dropdownRef}
      >
        <div
          className="container search-container"
          data-search-suggester="search-suggestions"
        >
          <div className="row">
            <div className="d-flex flex-wrap w-100">
              <div className="col-md-4 col-lg-3 mb-5 mb-lg-0 search-order-suggest">
                {Object.keys(typeAheadSuggestions)?.length || searchedTerm ? (
                  <TypeAheadSuggestionsList
                    suggestions={typeAheadSuggestions}
                    categorySuggestions={categorySuggestions}
                    onSuggestionClick={onTypeAheadClick}
                    headerText={formatMessage({
                      id: "suggestions",
                      defaultMessage: "Suchvorschläge",
                    })}
                    searchTerm={searchedTerm}
                  />
                ) : (
                  <TypeAheadSuggestionsList
                    onSuggestionClick={onTypeAheadClick}
                    suggestions={memoizedPreviousSearch}
                    categorySuggestions={memoizedVisitedCategories}
                    headerText={formatMessage({
                      id: "recently-searched",
                      defaultMessage: "Zuletzt gesucht",
                    })}
                    searchTerm={searchedTerm}
                  />
                )}
              </div>
              <div className="col-md-8 col-lg-5 mb-5 mb-lg-0 search-order-products">
                {productSuggestions?.length || searchedTerm ? (
                  <>
                    <SuggestedProductList
                      suggestions={productSuggestions}
                      header={formatMessage({
                        id: "suggested-products-header",
                        defaultMessage: "Produkte",
                      })}
                      searchTerm={searchedTerm}
                      showMigelConformity={showMigelConformity}
                    />
                    {foundProducts ? (
                      <button
                        onClick={onViewAllProductMatch}
                        type="button"
                        className="no-button link d-block mt-3"
                      >
                        {formatMessage(
                          {
                            id: "view-all-product-matches",
                            defaultMessage:
                              "Alle {foundProducts} gefundenen Produkte anzeigen",
                          },
                          {
                            foundProducts,
                          },
                        )}
                      </button>
                    ) : null}
                  </>
                ) : (
                  <SuggestedProductList
                    suggestions={memoizedVisitedProducts}
                    header={formatMessage({
                      id: "visited-products-header",
                      defaultMessage: "Zuletzt besuchte Produkte",
                    })}
                    searchTerm={searchedTerm}
                    showMigelConformity={showMigelConformity}
                  />
                )}
              </div>
              <div className="col-md-12 col-lg-4 search-order-content">
                {contentSuggestions?.length || searchedTerm ? (
                  <SuggestedContentList
                    suggestions={contentSuggestions}
                    header={formatMessage({
                      id: "content-suggestion-header",
                      defaultMessage: "Inhalte",
                    })}
                    searchTerm={searchedTerm}
                  />
                ) : (
                  <SuggestedContentList
                    suggestions={memoizedVisitedContent}
                    header={formatMessage({
                      id: "visited-content-header",
                      defaultMessage: "Zuletzt besuchte Inhalte",
                    })}
                    searchTerm={searchedTerm}
                  />
                )}
              </div>
            </div>
          </div>
          {hasHistory && !searchedTerm ? (
            <button
              onClick={() => {
                clearSearchHistory();
                setRefresh(!refresh);
              }}
              type="button"
              className="font-size-small no-button link d-block mt-3"
            >
              {formatMessage({
                id: "clear-search-history",
                defaultMessage: "Verlauf löschen",
              })}
            </button>
          ) : null}
        </div>

        {!hasResult && searchedTerm && !inLoadingState ? (
          <p className="text-center">
            {formatMessage({
              id: "no-result",
              defaultMessage: "Kein Ergebnis",
            })}
          </p>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(SearchResultDropdown);
