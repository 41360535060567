import { useIntl } from "react-intl";
import React, { useMemo } from "react";
import boldMatch from "../../common/utils/boldMatch";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";
import LocalizedLink from "modules/layout/components/LocalizedLink";
import ImageWithFallback from "modules/common/components/ImageWithFallback";

// to make it work with previous results
const typeNameToCollectionNameMap = {
  newsModel: "stories",
  newsletterModel: "news",
  eventsTreeModel: "events",
};

const SuggestedContentListItem = ({ content, searchTerm }) => {
  const { formatMessage } = useIntl();
  const { image } = content;

  const CollectionToPageIdMap = useMemo(
    () => ({
      news: {
        pageId: findPageIdByFilePath("/newsletters/:slug"),
        pageName: formatMessage({
          id: "news",
          defaultMessage: "News",
        }),
        class: "color-bg-blue color-white",
      },
      stories: {
        pageId: findPageIdByFilePath("/stories"),
        pageName: formatMessage({
          id: "story",
          defaultMessage: "Story",
        }),
        class: "color-bg-blue-light",
      },
      pages: {},
      events: {
        pageId: findPageIdByFilePath("/events/:slug"),
        pageName: formatMessage({
          id: "academy",
          defaultMessage: "Academy",
        }),
        class: "color-bg-blue-lighter",
      },
    }),
    [formatMessage],
  );
  const normalizedCollectionType =
    content?.collectionName ||
    typeNameToCollectionNameMap?.[content?._typename];
  return (
    <LocalizedLink
      page={{
        pageId: CollectionToPageIdMap[normalizedCollectionType]?.pageId,
        pathname: content?.slug || content._id,
      }}
      key={content?._id}
    >
      <div className="searched-item py-2 py-md-1 pr-1 d-flex flex-no-wrap mb-2">
        <ImageWithFallback
          src={image?.path || "/static/img/no-image.png"}
          className="w-max-80px h-max-80px ml-1 cursor-pointer object-fit-cover"
          width={320}
          height={320}
          quality={80}
          alt={content?.title}
        />
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="mx-3">
            <span
              dangerouslySetInnerHTML={{
                __html: boldMatch({
                  value: content?.title,
                  highlight: searchTerm,
                }),
              }}
            />
          </div>
          <div
            className={`d-inline-block font-size-small py-1 px-2 rounded-full ${CollectionToPageIdMap[normalizedCollectionType]?.class}`}
          >
            {CollectionToPageIdMap[normalizedCollectionType]?.pageName}
          </div>
        </div>
      </div>
    </LocalizedLink>
  );
};

export default SuggestedContentListItem;
