import { useQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";

export const COCKPIT_FOOTER_MENU_NAME = "Footer";
export const COCKPIT_HEADER_MENU_NAME = "Header";
export const COCKPIT_MAIN_MENU_NAME = "Main";

export const CMS_MENU_QUERY = gql`
  query CMSMenu($name: String!, $locale: String) {
    pagesMenu(name: $name, locale: $locale)
  }
`;

const useMenu = ({ name }) => {
  const { locale } = useIntl();
  const { data, loading, error, ...rest } = useQuery(CMS_MENU_QUERY, {
    variables: { name, locale },
    skip: !name,
  });
  const availableLinks = data?.pagesMenu?.links?.filter(({ url }) => url);
  return {
    menu: data?.pagesMenu
      ? { ...data?.pagesMenu, links: availableLinks }
      : null,
    loading,
    error,
    ...rest,
  };
};

export default useMenu;
