import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Icon from "../../common/components/Icon";
import useProductStock from "../hooks/useProductStock";
import ProductStockIconPure from "./ProductStockIconPure";

const ProductStockIcon = ({ productId, onlyIcon = false }) => {
  const [ref, inView] = useInView();
  const {
    loadPrice,
    called,
    product = {},
    loading,
  } = useProductStock({
    productId,
  });

  useEffect(() => {
    if (inView && !called) {
      loadPrice();
    }
  });
  return (
    <div ref={ref}>
      {loading && <Icon icon="loading" />}
      {product && (
        <ProductStockIconPure
          stocks={product.simulatedStocks}
          dispatches={product.simulatedDispatches}
          onlyIcon={onlyIcon}
        />
      )}
    </div>
  );
};

export default ProductStockIcon;
