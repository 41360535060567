import { useQuery, gql } from "@apollo/client";
import { useEffect, useState } from "react";

export const SearchSuggestionsQuery = gql`
  query SearchSuggestionsQuery(
    $queryString: String!
    $contentLimit: Int
    $suggestLimit: Int
    $assortmentLimit: Int
  ) {
    autoSuggestProducts(queryString: $queryString, limit: $suggestLimit) {
      _id
      text
      locale
    }
    searchCms(queryString: $queryString, limit: $contentLimit) {
      stories
      events
      news
    }

    searchProducts(queryString: $queryString) {
      productsCount
      products(limit: $contentLimit) {
        _id
        status
        texts {
          _id
          slug
          title
          subtitle
        }
        ... on SimpleProduct {
          sku
        }
        media {
          _id
          file {
            _id
            small: url(version: "SMALL")
          }
        }

        ... on SimpleProduct {
          publicare {
            cutting
            migel
            isNotReturnable
            isOnlyForMedProfessionals
            clientMigelConformity
            institutionalMigelConformity
          }
        }
      }
    }

    searchAssortments(queryString: $queryString) {
      assortments(limit: $assortmentLimit) {
        _id
        texts {
          _id
          title
          subtitle
          slug
        }
        assortmentPaths {
          links {
            assortmentTexts {
              _id
              slug
              title
              subtitle
              __typename
            }
          }
        }
      }
    }
  }
`;

const useSearchSuggestions = ({
  queryString,
  contentLimit,
  suggestLimit,
  assortmentLimit = 4,
}) => {
  const [previousData, setPreviousData] = useState({});
  const { loading, error, data } = useQuery(SearchSuggestionsQuery, {
    skip: !queryString,

    variables: { queryString, contentLimit, suggestLimit, assortmentLimit },
  });

  const currentData = data || previousData;

  const products = currentData?.searchProducts?.products || [];
  const totalMatchedProducts = currentData?.searchProducts?.productsCount || 0;
  const suggestions = currentData?.autoSuggestProducts || [];
  const assortments = currentData?.searchAssortments?.assortments || [];
  const contents = Object.entries(currentData?.searchCms || {}).reduce(
    (prev, [collectionName, obj]: any) => {
      if (Array.isArray(obj)) {
        return [...prev, ...obj].map((o) => ({ collectionName, ...o }));
      }
      return [...prev];
    },
    [],
  );

  useEffect(() => {
    if (data && !loading) {
      setPreviousData(data);
    }
  }, [loading]);
  return {
    loading,
    error,
    suggestions,
    products,
    contents,
    totalMatchedProducts,
    assortments,
  };
};

export default useSearchSuggestions;
