import { useIntl } from "react-intl";

const PRODUCT_STORAGE_KEY = "visitedProducts";
const CONTENT_STORAGE_KEY = "visitedContents";
const SEARCHED_TEXT_STORAGE_KEY = "searchStrings";
const ASSORTMENT_STORAGE_KEY = "visitedCategories";

const useSuggester = () => {
  const { locale } = useIntl();

  const parseUniqueItems = (values) => {
    if (!values?.length) return [];
    return Object.values(
      values.reduce((prev, curr) => {
        return { ...prev, [curr._id]: curr };
      }, {}),
    );
  };

  const tagAsStorage = (val) => {
    if (typeof val === "string") return { _id: `stored-${val}`, text: val };
    const { _id: id, ...rest } = val;
    return { ...rest, _id: `stored-${id}` };
  };

  const getPreviousSearchStrings = () => {
    if (typeof window === "undefined") return [];
    return (
      JSON.parse(
        window.localStorage.getItem(SEARCHED_TEXT_STORAGE_KEY) || "{}",
      )[locale] ?? []
    );
  };

  const storeSearchString = (value) => {
    const previousSearches = getPreviousSearchStrings();
    const searched = parseUniqueItems([
      tagAsStorage(value),
      ...previousSearches,
    ]);
    searched.splice(8);
    window.localStorage.setItem(
      SEARCHED_TEXT_STORAGE_KEY,
      JSON.stringify({ [locale]: searched }),
    );
  };

  const getPreviousVisitedProducts = () => {
    if (typeof window === "undefined") return [];
    return (
      JSON.parse(window.localStorage.getItem(PRODUCT_STORAGE_KEY) || "{}")[
        locale
      ] ?? []
    );
  };

  const storeVisitedProduct = (value) => {
    const previousVisited = getPreviousVisitedProducts();
    if (typeof window !== "undefined") {
      const visited = parseUniqueItems([
        tagAsStorage(value),
        ...previousVisited,
      ]);
      visited.splice(4);
      try {
        window.localStorage.setItem(
          PRODUCT_STORAGE_KEY,
          JSON.stringify({ [locale]: visited }),
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getPreviousVisitedContent = () => {
    if (typeof window === "undefined") return [];
    return (
      JSON.parse(window.localStorage.getItem(CONTENT_STORAGE_KEY) || "{}")[
        locale
      ] ?? []
    );
  };

  const storeVisitedContent = (value: { collectionName: string } & any) => {
    const previousVisited = getPreviousVisitedContent();
    if (typeof window !== "undefined") {
      const visited = parseUniqueItems([
        tagAsStorage(value),
        ...previousVisited,
      ]);
      visited.splice(4);
      try {
        window.localStorage.setItem(
          CONTENT_STORAGE_KEY,
          JSON.stringify({ [locale]: visited }),
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getPreviousVisitedCategories = () => {
    if (typeof window === "undefined") return [];
    return (
      JSON.parse(window.localStorage.getItem(ASSORTMENT_STORAGE_KEY) || "{}")[
        locale
      ] ?? []
    );
  };

  const storeVisitedCategory = (value) => {
    const previousVisited = getPreviousVisitedCategories();
    if (typeof window !== "undefined") {
      const visited = parseUniqueItems([
        tagAsStorage(value),
        ...previousVisited,
      ]);
      visited.splice(4);
      try {
        window.localStorage.setItem(
          ASSORTMENT_STORAGE_KEY,
          JSON.stringify({ [locale]: visited }),
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const clearSearchHistory = () => {
    localStorage.setItem(CONTENT_STORAGE_KEY, "{}");
    localStorage.setItem(PRODUCT_STORAGE_KEY, "{}");
    localStorage.setItem(SEARCHED_TEXT_STORAGE_KEY, "{}");
    localStorage.setItem(ASSORTMENT_STORAGE_KEY, "{}");
  };

  return {
    getPreviousSearchStrings,
    storeSearchString,
    getPreviousVisitedContent,
    storeVisitedContent,
    getPreviousVisitedProducts,
    storeVisitedProduct,
    clearSearchHistory,
    storeVisitedCategory,
    getPreviousVisitedCategories,
  };
};

export default useSuggester;
