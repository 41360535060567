import { useState } from "react";
import useDebounce from "./useDebounce";

const useDebouncedSearchTerm = (initialValue, debounceDelay) => {
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [debouncedSearchTerm, debounced] = useDebounce(
    searchTerm,
    debounceDelay,
  );

  return [searchTerm, setSearchTerm, debouncedSearchTerm, debounced];
};

export default useDebouncedSearchTerm;
