import classNames from "classnames";
import useCurrentClient from "modules/auth/hooks/useCurrentClient";
import Icon from "modules/common/components/Icon";
import Tooltip from "modules/common/components/Tooltip";
import { useIntl } from "react-intl";

const MigelConformityInfoText = ({ migelConformity }) => {
  const { formatMessage } = useIntl();

  switch (migelConformity) {
    case "CONFORMANT":
      return (
        <Tooltip
          showIconInTooltipMessage
          title={formatMessage({
            id: "article-migel",
            defaultMessage: "MiGeL-konform",
          })}
          description={formatMessage({
            id: "article-migel-tooltip",
            defaultMessage: "Dieses Produkt wird von der Versicherung vergütet",
          })}
        >
          <Icon icon="migel-ok" className="icon--large" />
        </Tooltip>
      );
    case "NON_CONFORMANT":
      return (
        <Tooltip
          title={formatMessage({
            id: "article-not-migel",
            defaultMessage: "Nicht MiGeL-konform",
          })}
          showIconInTooltipMessage
          description={formatMessage({
            id: "article-not-migel-tooltip",
            defaultMessage:
              "Dieses Produkt enthält Selbstkosten, die nicht von der Versicherung vergütet werden",
          })}
        >
          <Icon icon="migel-nok" className="icon--large" />
        </Tooltip>
      );
    case "DEFAULT":
      return (
        <Tooltip
          showIconInTooltipMessage
          description={formatMessage({
            id: "can_include_costs_detailed",
            defaultMessage:
              "Dieses Produkt kann Selbstkosten enthalten, die nicht von der Versicherung vergütet werden.",
          })}
        ></Tooltip>
      );

    default:
      return null;
  }
};

const MigelConformity = ({
  clientMigelConformity,
  institutionalMigelConformity,
  onlyIcon = false,
  className = "",
}) => {
  const { formatMessage } = useIntl();
  const { currentClient } = useCurrentClient();

  if (!currentClient) {
    return null;
  }

  const migelConformity = currentClient.isInstitution
    ? institutionalMigelConformity
    : clientMigelConformity;

  if (!migelConformity) return null;

  return (
    <div
      className={classNames(`d-flex align-items-center`, className)}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onTouchStart={(e) => {
        e.stopPropagation();
      }}
    >
      <MigelConformityInfoText migelConformity={migelConformity} />
      {!onlyIcon && (
        <span className="ml-2 migel-label">
          {formatMessage({
            id: "migel-conformity",
            defaultMessage: "MiGeL-Konformität",
          })}
        </span>
      )}
    </div>
  );
};

export default MigelConformity;
