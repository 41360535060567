export enum Availability {
  "in_stock",
  "out_of_stock",
  "stock_unknown",
  "unknown_delivery",
  "deleted_product",
}

const getAvailability = ({ stocks = null, dispatches = null } = {}): {
  availability: keyof typeof Availability;
  isInStock: boolean;
  earliestDeliveryDate?: Date;
} => {
  if (!dispatches && !stocks) {
    return {
      availability: "deleted_product",
      isInStock: false,
      earliestDeliveryDate: null,
    };
  }

  if (stocks?.[0]?.quantity === 99999999) {
    return {
      availability: "stock_unknown",
      isInStock: true,
      earliestDeliveryDate: null,
    };
  }

  const isInStock =
    stocks.reduce((total, stock) => total + stock.quantity, 0) > 0;
  const earliestDeliveryDate = dispatches?.[0]?.earliestDelivery;

  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);

  if (
    earliestDeliveryDate &&
    new Date(earliestDeliveryDate) < threeMonthsFromNow
  ) {
    if (isInStock) {
      return {
        availability: "in_stock",
        isInStock,
        earliestDeliveryDate,
      };
    }

    if (!isInStock) {
      return {
        availability: "out_of_stock",
        isInStock,
        earliestDeliveryDate,
      };
    }
  }
  return {
    availability: "unknown_delivery",
    isInStock,
    earliestDeliveryDate,
  };
};

export default getAvailability;
