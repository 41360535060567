import Tooltip from "modules/common/components/Tooltip";
import { useIntl } from "react-intl";
import Icon from "../../common/components/Icon";
import getAvailability from "../utils/getAvailability";

const ProductStockIconPure = ({ stocks, dispatches, onlyIcon }) => {
  const intl = useIntl();
  const { availability, earliestDeliveryDate } = getAvailability({
    stocks,
    dispatches,
  });
  const earliestDelivery = earliestDeliveryDate
    ? intl.formatMessage(
        {
          id: "estimated_delivery_date",
          defaultMessage:
            "Voraussichtlicher Liefertermin: {date}, falls bis 17.00 Uhr bestellt",
        },
        {
          date: (
            <span className="delivery-time">
              {intl.formatDate(earliestDeliveryDate, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </span>
          ),
        },
      )
    : "";
  if (availability === "stock_unknown")
    return (
      <Tooltip
        title={intl.formatMessage({
          id: "stock_unknown_small",
          defaultMessage: "Bestand unbekannt",
        })}
        description={earliestDelivery}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Icon
            icon="information-circle"
            fill="currentColor"
            aria-label="info"
            className="mr-2"
          />
          {!onlyIcon && (
            <small className="line-height-1-22 font-size-small">
              {intl.formatMessage({
                id: "stock_unknown_small",
                defaultMessage: "Bestand unbekannt",
              })}
            </small>
          )}
        </div>
      </Tooltip>
    );

  if (availability === "in_stock")
    return (
      <Tooltip
        title={intl.formatMessage({
          id: "in_stock_small_tooltip",
          defaultMessage: "Ab Lager",
        })}
        description={earliestDelivery}
      >
        <div
          className="d-flex align-items-center"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Icon icon="shipment-ok" aria-label="check" className="icon--large" />
          {!onlyIcon && (
            <small className="ml-2 line-height-1-22 font-size-small">
              {intl.formatMessage({
                id: "in_stock_small",
                defaultMessage: "Ab Lager",
              })}
            </small>
          )}
        </div>
      </Tooltip>
    );
  if (availability === "out_of_stock")
    return (
      <Tooltip
        title={intl.formatMessage({
          id: "out_of_stock_small",
          defaultMessage: "Nicht an Lager",
        })}
        description={earliestDelivery}
      >
        <div
          className="d-flex align-items-center"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Icon
            icon="shipment-nok"
            aria-label="out-of-stock"
            className="icon--large"
          />
          {!onlyIcon && (
            <small className="ml-2 line-height-1-22 font-size-small">
              {intl.formatMessage({
                id: "out_of_stock_small",
                defaultMessage: "Nicht an Lager",
              })}
            </small>
          )}
        </div>
      </Tooltip>
    );
  if (availability === "unknown_delivery")
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <span
          className="product__availability-icon d-inline-flex justify-content-center align-items-center mr-2 rounded-circle icon-remove color-bg-red"
          aria-label="unknown"
        />
        <small className="line-height-1-22">
          {intl.formatMessage({
            id: "unknown_delivery",
            defaultMessage: "Aktuell nicht lieferbar",
          })}
        </small>
      </div>
    );
};

export default ProductStockIconPure;
