import { useState, useEffect } from "react";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [debounced, setDebounced] = useState(false);

  useEffect(() => {
    setDebounced(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setDebounced(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, debounced, setDebouncedValue];
}
export default useDebounce;
