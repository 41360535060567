import React from "react";
import { useIntl } from "react-intl";

const LanguageSwitch = () => {
  const intl = useIntl();
  const currentLang = intl.locale.split("-")[0];

  const jump = (e) => {
    const alternateLink = document.querySelector(
      `head > link[hreflang=${e.target.value}]`,
    );
    const href = alternateLink?.href || `/${e.target.value}`;
    document.location.href = href;
  };

  return (
    <div className="select-wrap language-select">
      <label htmlFor="language-switcher" className="d-none">
        {intl.formatMessage({
          id: "choose-language",
          defaultMessage: "Wählen Sie eine Sprache",
        })}
      </label>
      <select value={currentLang} onChange={jump} id="language-switcher">
        <option value="de">DE</option>
        <option value="fr">FR</option>
        <option value="it">IT</option>
      </select>
    </div>
  );
};

export default LanguageSwitch;
