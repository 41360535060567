import React from "react";
import SuggestedProductListItem from "./SuggestedProductListItem";

const SuggestedProductList = ({
  suggestions,
  header,
  searchTerm,
  showMigelConformity,
}) => {
  if (!suggestions?.length) return null;
  return (
    <>
      <div className="font-weight-600 mb-3 opacity-78">{header}</div>
      {suggestions.map((suggestion) => (
        <SuggestedProductListItem
          key={suggestion._id}
          product={suggestion}
          searchTerm={searchTerm}
          showMigelConformity={showMigelConformity}
        />
      ))}
    </>
  );
};

export default React.memo(SuggestedProductList);
