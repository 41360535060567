import React from "react";
import SuggestedContentListItem from "./SuggestedContentListItem";

const SuggestedContentList = ({ suggestions, header, searchTerm }) => {
  if (!suggestions?.length) return null;
  return (
    <>
      <div className="font-weight-600 mb-3 opacity-78">{header} </div>
      {suggestions.map((suggestion) => (
        <SuggestedContentListItem
          key={suggestion._id}
          content={suggestion}
          searchTerm={searchTerm}
        />
      ))}
    </>
  );
};

export default SuggestedContentList;
