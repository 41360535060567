const boldMatch = ({ value, highlight }) => {
  if (!highlight) return value;
  const regexp = new RegExp(highlight, "gi");
  const highlightedString = Array.from(
    new Set(value.match(regexp)) as unknown as string[],
  )
    .reduce((prev, curr) => {
      return prev.replaceAll(curr, `<>${curr}</>`);
    }, value)
    .replaceAll("<>", "<b>")
    .replaceAll("</>", "</b>");

  return highlightedString;
};

export default boldMatch;
