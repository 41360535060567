// TODO: Fix a11y stuff
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useRouter } from "next/router";

import DesktopNavigationContext from "./DesktopNavigationContext";
import MegaDropdown from "./MegaDropdown";

import useShopMenu from "modules/layout/hooks/useShopMenu";
import LocalizedLink from "modules/layout/components/LocalizedLink";
import useCMSAdvantages from "modules/layout/hooks/useCMSAdvantages";
import { useIntl } from "react-intl";

const DesktopNavigation = () => {
  const router = useRouter();
  const { locale } = useIntl();
  const { menu } = useShopMenu({ locale });

  const [hoverPath, setHoverPath] = useState([]);
  const { advantages = [] } = useCMSAdvantages();

  const [isTouching, setTouching] = useState(false);
  const navigatedPath = router.asPath;

  const handleTouchStart = () => {
    setTouching(true);
  };

  const handleTouchEnd = () => {
    setTimeout(() => setTouching(false), 300);
  };

  return (
    <DesktopNavigationContext.Provider
      value={{ // eslint-disable-line
        setHoverPath,
        navigatedPath,
        hoverPath,
        isTouching,
      }}
    >
      <nav
        className="nav nav--main"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {menu.map((node, index) => (
          <div key={node.url.route} className="d-inline-block font-size-0">
            <LocalizedLink
              page={{ pageId: node.url.route }}
              className="nav--main__item"
              data-in-navigation-path={navigatedPath.includes(node.url.route)}
              data-in-hover-path={hoverPath?.includes(node.url.route)}
              onMouseEnter={() => {
                setHoverPath(node.url.route);
              }}
              onMouseOut={() => {
                setHoverPath("");
              }}
              onBlur={() => {
                if (!isTouching) setHoverPath("");
              }}
            >
              {node.title}
            </LocalizedLink>

            {hoverPath?.includes(node.url.route) && node.children.length ? (
              <MegaDropdown
                pageId={node.url.route}
                node={node}
                advantage={advantages[index % advantages.length]}
              />
            ) : (
              ""
            )}
          </div>
        ))}
      </nav>
    </DesktopNavigationContext.Provider>
  );
};

export default DesktopNavigation;
