import React from "react";

import MegaDropdownColumn from "./MegaDropdownColumn";
import { useDesktopNavigationContext } from "./DesktopNavigationContext";
import Icon from "../../common/components/Icon";

const findChildBySlug = (node, slug) => {
  return (
    node?.children?.length &&
    node.children
      .filter(({ url }) => url)
      .find((childNode) => childNode.url.route.includes(slug))
  );
};

const getColumn = (node, hoverPath, navigatedPath, columnIndex) => {
  if (hoverPath.length <= columnIndex || !node) {
    return null;
  }
  const hoverPathsArr = hoverPath.split("/");

  if (hoverPath?.includes(node.url.route)) {
    return findChildBySlug(node, hoverPathsArr[columnIndex]) || null;
  }
  if (navigatedPath.includes(node.url.route)) {
    return findChildBySlug(node, navigatedPath[columnIndex]) || null;
  }
  return null;
};

const MegaDropdown = ({ node, pageId, advantage }) => {
  const { setHoverPath, navigatedPath, hoverPath, isTouching } =
    useDesktopNavigationContext();

  const secondColumnNode = getColumn(node, hoverPath, navigatedPath, 2);

  const thirdColumnNode = getColumn(
    secondColumnNode,
    hoverPath,
    navigatedPath,
    3,
  );

  return (
    <div
      className="nav--main__mega"
      onMouseEnter={() => {
        if (!isTouching) setHoverPath(pageId);
      }}
      onMouseLeave={() => {
        if (!isTouching) setHoverPath("");
      }}
      onBlur={() => {
        if (!isTouching) setHoverPath("");
      }}
    >
      <MegaDropdownColumn
        pageId={node.url.route}
        node={node}
        key="mega-dropdown-column-1"
      />
      {secondColumnNode?.children?.length ? (
        <MegaDropdownColumn
          pageId={secondColumnNode.url.route}
          node={secondColumnNode}
          key="mega-dropdown-column-2"
        />
      ) : (
        <div className="mega-col" />
      )}
      {thirdColumnNode?.children?.length ? (
        <MegaDropdownColumn
          pageId={thirdColumnNode.url.route}
          node={thirdColumnNode}
          key="mega-dropdown-column-3"
        />
      ) : (
        <div className="mega-col mega-col-last">
          {advantage && (
            <div className="text-center">
              <p className="mt-0">{advantage.label}</p>
              <Icon
                className="icon--l mx-auto d-flex icon-circle justify-content-around align-items-center"
                icon="Positiv"
              />
              <h5 className="mt-2 mb-1">
                <b>{advantage.title}</b>
              </h5>
              <div>{advantage.text}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MegaDropdown;
