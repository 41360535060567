import { useLocalizedRoute } from "modules/layout/components/LocalizedLink";
import { useRouter } from "next/router";
import React from "react";
import { useIntl } from "react-intl";

import SuggestionListItem from "./SuggestionListItem";

const mapAssortmentRoot = ({ assortmentPaths, ...rest }) => {
  const [paths] = assortmentPaths;
  const [pathLink] = paths?.links || [];

  return {
    rootAssortment: pathLink?.assortmentTexts,
    ...rest,
  };
};

const TypeAheadSuggestionsList = ({
  suggestions,
  onSuggestionClick,
  headerText,
  categorySuggestions,
  searchTerm,
}) => {
  const { formatMessage } = useIntl();
  const router = useRouter();

  const hasProductSuggestion = !!(suggestions || []).length;
  const hasCategorySuggestion = !!(categorySuggestions || []).length;

  if (!hasProductSuggestion && !hasCategorySuggestion) return null;

  const handleClick = (suggestion) => {
    if (suggestion.type === "category") {
      router.push(`/shop/${suggestion.texts.slug}`);
    } else {
      onSuggestionClick({
        ...suggestion,
        text: suggestion?.text.replace(/<b>|<\/b>/g, ""),
      });
    }
  };

  const normalizedProducts = suggestions.slice(
    0,
    8 - categorySuggestions.length,
  );
  const normalizedCategories = (categorySuggestions || [])
    .map(mapAssortmentRoot)
    .slice(0, 4);
  return (
    <>
      {hasCategorySuggestion && (
        <div className="font-weight-600 mb-3 opacity-78">
          {formatMessage({
            id: "categories-suggestions",
            defaultMessage: "Categories",
          })}
        </div>
      )}
      {(normalizedCategories || [])?.map((suggestion, currentIndex) => {
        const {
          texts: { title: text },
        } = suggestion;
        return (
          <SuggestionListItem
            key={suggestion._id}
            onSuggestionClick={() =>
              handleClick({ ...suggestion, type: "category" })
            }
            suggestion={{ ...suggestion, text }}
            searchTerm={searchTerm}
            data-is-last={
              currentIndex === normalizedCategories.length - 1 || undefined
            }
          />
        );
      })}
      <div className="font-weight-600 mb-3 opacity-78">{headerText}</div>
      {normalizedProducts?.map((suggestion) => (
        <SuggestionListItem
          onSuggestionClick={() => handleClick(suggestion)}
          suggestion={suggestion}
          key={suggestion._id}
          searchTerm={searchTerm}
        />
      ))}
    </>
  );
};

export default React.memo(TypeAheadSuggestionsList);
